import React from 'react'
import { graphql } from 'gatsby'
import PageDefault from './page-default'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location }) => {
  const page = data.ghostPage

	return <PageDefault data={data} location={location} />
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
