import React from 'react'
import SimpleReactValidator from 'simple-react-validator'
import SVG from 'react-inlinesvg'
import { Player } from '@lottiefiles/react-lottie-player';

import '../../styles/components/contact-form.scss'

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      surnameValue: '',
      emailValue: '',
      phoneValue: '',
      servicesValue: '',
      messageValue: '',
      acceptValue: '',
			pathValue: '',
			loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.validator = new SimpleReactValidator({
      locale: 'en',
      messages: { default: 'Αυτό το πεδίο είναι υποχρεωτικό.' },
			validators: {
				alphaWithGreek: {
					message: 'Αυτό το πεδίο είναι υποχρεωτικό.',
					rule: (val, params, validator) => {
						return validator.helpers.testRegex(val,/^[a-zA-Z\u0370-\u03ff\u1f00-\u1fff]*$/i) && params.indexOf(val) === -1
					}
				}
			}
    })
  }

  componentDidMount() {
    const MESSAGE = document.querySelector('.contact-form__message')
    const MESSAGE_TEXT = document.getElementById('contact_form_message_text')

    this.setState({ pathValue: window.location.href })

    document.getElementById('contact_form_message_close').addEventListener('click', () => {
      MESSAGE.classList.remove('is-visible')
      setTimeout(()=>{
        MESSAGE.style.display = 'none'
      }, 200)
      MESSAGE.classList.remove('error', 'success')
      MESSAGE_TEXT.innerText = ''
    })
  }

  handleChange(field, event) {
    field == 'acceptValue'
      ? this.setState({ acceptValue: event.target.checked })
      : this.setState({ [field]: event.target.value });
  }

  handleMessage(el, text, type, message) {
    el.style.display = 'flex'
    el.classList.add(type)
    text.innerText = message
    setTimeout(()=>{
      el.classList.add('is-visible')
    }, 200)
  }

  resetForm() {
    this.setState({ nameValue: '' })
    this.setState({ surnameValue: '' })
    this.setState({ emailValue: '' })
    this.setState({ phoneValue: '' })
    this.setState({ servicesValue: '' })
    this.setState({ messageValue: '' })
    this.setState({ acceptValue: '' })
    document.getElementById('contact_form').reset()
  }

  handleSubmit(event) {
    const MESSAGE = document.querySelector('.contact-form__message')
    const MESSAGE_TEXT = document.getElementById('contact_form_message_text')

    event.preventDefault()
    if (this.validator.allValid()) {
			this.setState({ loading: true })

      fetch(`https://thinkplus.activehosted.com/proc.php?u=3&f=3&s=&c=0&m=0&act=sub&v=2&firstname=${encodeURIComponent(this.state.nameValue)}&lastname=${encodeURIComponent(this.state.surnameValue)}&email=${encodeURIComponent(this.state.emailValue)}&phone=${encodeURIComponent(this.state.phoneValue)}&field[1]=${encodeURIComponent(this.state.servicesValue)}&field[2]=${encodeURIComponent(this.state.messageValue)}&field[3][]=~%7C&field[3][]=${this.state.acceptValue && encodeURIComponent(document.getElementById('contact_form_accept').value)}&field[4]=${encodeURIComponent(this.state.pathValue)}&jsonp=true`, {
        method: 'GET',
				mode: 'no-cors',
				cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response=>response.text())
        .then(() => {
					this.handleMessage(MESSAGE, MESSAGE_TEXT, 'success', 'Το μήνυμά σας στάλθηκε επιτυχώς.');
        })
        .catch(() => {
            this.handleMessage(MESSAGE, MESSAGE_TEXT, 'error', 'Παρουσιάστηκε κάποιο πρόβλημα!');
          })
        .finally(() => {
          this.setState({ loading: false })
          this.resetForm()
        });

      this.validator.hideMessages()
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  render() {
    return (
      <section className="contact-form dec-section--line-vertical dec-section--box-bottom" id="contact_form_section">
        <div className="container">
          <div className="row">
            <div className="col col-xs-no-padding">
              <div className="contact-form__container">
                <h2 className="contact-form__title h3 dec-header--underline">Ας δούμε μαζί το project σας!</h2>
                <p className="contact-form__text">Επικοινωνήστε τηλεφωνικά μαζί μας: <a href="tel:+302106101478">+30 210 61 01 478</a> | Δευτέρα - Παρασκευή 10:00 - 18:00&nbsp;<br />ή συμπληρώστε την φόρμα για να επικοινωνήσουμε άμεσα μαζί σας.</p>
								<div className="contact-form__illustration">
									<Player
										src="/Chatbot-Colour-Animation.json"
										autoplay
										loop
										style={{ height: '25vw', width: '25vw', minHeight: '250px', minWidth: '250px', maxHeight: '550px', maxWidth: '550px' }}
									/>
								</div>
                <form id="contact_form" >
                  <div className="container--fluid">
                    <div className="row">
                      <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
                        <label htmlFor="contact_form_name" className="visually-hidden">Όνομα:*</label>
                        <input type="text" name="entry.1245707713" className="contact-form__input-general" id="contact_form_name" placeholder="Όνομα:*" required value={this.state.nameValue} onChange={(event) => { this.handleChange('nameValue', event) }} />
                        {this.validator.message('name', this.state.nameValue, 'required|alphaWithGreek')}
                      </div>
                      <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
                        <label htmlFor="contact_form_surname" className="visually-hidden">Επώνυμο:*</label>
                        <input type="text" name="entry.1065253110" className="contact-form__input-general" id="contact_form_surname" placeholder="Επώνυμο:*" required value={this.state.surnameValue} onChange={(event) => { this.handleChange('surnameValue', event) }} />
                        {this.validator.message('surname', this.state.surnameValue, 'required|alphaWithGreek')}
                      </div>
                      <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
                        <label htmlFor="contact_form_email" className="visually-hidden">Email:*</label>
                        <input type="email" name="entry.1643361296" className="contact-form__input-general" id="contact_form_email" placeholder="Email:*" required value={this.state.emailValue} onChange={(event) => { this.handleChange('emailValue', event) }} />
                        {this.validator.message('email', this.state.emailValue, 'required|email')}
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <label htmlFor="contact_form_telephone" className="visually-hidden">Τηλέφωνο Επικοινωνίας:*</label>
                        <input type="tel" name="entry.1005264848" className="contact-form__input-general" id="contact_form_telephone" placeholder="Τηλέφωνο Επικοινωνίας:*" required value={this.state.phoneValue} onChange={(event) => { this.handleChange('phoneValue', event) }} />
                        {this.validator.message('phone', this.state.phoneValue, 'required|phone')}
                      </div>
                    </div>
                    {/* <fieldset>
                      <div className="row">
                        <div className="col">
                          <legend className="h5">Επιλέξτε την υπηρεσία που σας ενδιαφέρει</legend>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <span className="contact-form__checkbox-label">
                            <input type="radio" id="digital_marketing" name="entry.1366955423" value="Ενέργειες digital marketing" onChange={(event) => { this.handleChange('servicesValue', event) }} />
                            <label htmlFor="digital_marketing">Ενέργειες digital marketing</label>
                          </span>
                          <span className="contact-form__checkbox-label">
                            <input type="radio" id="eshop" name="entry.1366955423" value="Κατασκευή E-Shop" onChange={(event) => { this.handleChange('servicesValue', event) }} />
                            <label htmlFor="eshop">Κατασκευή E-Shop</label>
                          </span>
                          <span className="contact-form__checkbox-label">
                            <input type="radio" id="web_site" name="entry.1366955423" value="Κατασκευή website" onChange={(event) => { this.handleChange('servicesValue', event) }} />
                            <label htmlFor="web_site">Κατασκευή website</label>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <span className="contact-form__checkbox-label">
                            <input type="radio" id="redesign" name="entry.1366955423" value="Τροποποίηση υπάρχουσας ιστοσελίδας" onChange={(event) => { this.handleChange('servicesValue', event) }} />
                            <label htmlFor="redesign">Τροποποίηση υπάρχουσας ιστοσελίδας</label>
                          </span>
                          <span className="contact-form__checkbox-label">
                            <input type="radio" id="web_shop" name="entry.1366955423" value="Shopify Application" onChange={(event) => { this.handleChange('servicesValue', event) }} />
                            <label htmlFor="web_shop">Shopify Application</label>
                          </span>
                          <span className="contact-form__checkbox-label">
                            <input type="radio" id="something_else" name="entry.1366955423" value="Κάτι άλλο" onChange={(event) => { this.handleChange('servicesValue', event) }} />
                            <label htmlFor="something_else">Κάτι άλλο</label>
                          </span>
                        </div>
                      </div>
                    </fieldset> */}
                    <div className="row contact-form__margin">
                      <div className="col">
                        <label htmlFor="contact_form_message" className="visually-hidden">Περιγραφή project (Προαιρετικό)</label>
                        <textarea name="entry.754854292" cols="40" rows="3" className="contact-form__input-general" id="contact_form_message" placeholder="Περιγραφή project (Προαιρετικό)" value={this.state.messageValue} onChange={(event) => { this.handleChange('messageValue', event) }} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="contact_form_accept" className="contact-form__text-info contact-form__acceptance" onClick={(event) => { this.handleChange('acceptValue', event) }} >
                          <input type="checkbox" name="entry.763567046" value="Δέχομαι" id="contact_form_accept" required/>
                          {this.validator.message('accepted', this.state.acceptValue, 'accepted')}
                          *Επικοινωνώντας μαζί μας αποδέχεστε τους όρους χρήσης και ιδιωτικού απορρήτου της Think Plus και συμφωνείτε να λαμβάνετε ενημερωτικά email από την Think Plus.</label>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-md-6">
                        <p className="contact-form__text-info">Απαντάμε σε όλες τις επικοινωνίες σε λιγότερο από 12 ώρες και τις περισσότερες φορές σε λιγότερο από 1 ώρα!</p>
                      </div> */}
                      <div className="col text-align-center">
                        <input type="hidden" name="entry.1208163431" value={this.state.pathValue}></input>
                        <button type="submit" onClick={(event) => { this.handleSubmit(event) }} className="btn btn__theme--inverted" disabled={this.state.loading}>Αποστολή<span className={`loading${this.state.loading && ' show'}`}></span></button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="container">
                  <div className="contact-form__message" style={{ display: 'none' }}>
                    <span id="contact_form_message_close">
                      <SVG src="/images/icons/x-mark.svg" />
                    </span>
                    <p id="contact_form_message_text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactForm
